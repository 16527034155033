import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Branches of healthcare that require skills in Otoscopy:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Otolaryngology  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Primary Care  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Pediatrics  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Family Medicine  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Emergency Medicine  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Neurology  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Audiology  `}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  