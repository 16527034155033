import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Featured content & material:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Instrument Use`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Feature Identification`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Pathology`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`External Auditory Canal`}</MDXTag>{` - Acute Otitis Externa, Cerumen, Foreign Bodies, and more`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Middle Ear`}</MDXTag>{` - Normal Tympanic Membrane, Temporal Bone Fractures, Acute Otitis Media, Serous Otitis Media, Tympanocentesis, Myringotomy, and more`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Pneumatic Otoscopy - Simulated slides to enhance students' pneumatic otoscopy skills – available with purchase of PneumatoSim™ add-on only`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Self Examination and Anatomy Examination`}</MDXTag>
</MDXTag>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    OtoSim 2™ includes:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Base Unit`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Right Earform + Left Earform`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Instrumented Otoscope`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Control Box`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`OtoSim 2™ Software Package & Manual`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Protective Hard Cases`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`1-Year Warranty (Extended Warranties for a total of 2 or 3 years are available for purchase)`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  