import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="2" size="small" responsive={true} alignSelf="center">
    "95% of graduating medical students do not feel comfortable with their level of otology skills at the end of their clerkship."
</Heading></MDXTag>

export const _frontmatter = {};

  