import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OtoSim 2™ is the ultimate otoscopy simulation trainer built for teaching and testing otoscopy skills. Over 500 programs worldwide already use OtoSim 2™ for their simulation scenarios and OSCE examinations.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  