import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';
import { Link } from 'gatsby';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Accessories:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p"><Link to="/pneumatosim">{`PneumatoSim™`}</Link>{`:`}</MDXTag>{` software and hardware add-on package for OtoSim 2™ for teaching pneumatic otoscopy. Package includes:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`PneumatoSim software upgrade`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Custom silicone earform for pneumatic otoscopy`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Pneumatic otoscope with bulb for simulation purposes only`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p"><Link to="/educators-toolkit">{`Educators Toolkit™`}</Link>{`:`}</MDXTag>{` Software add-on to OtoSim 2™ for customized education sessions and mass training. Features enable instructors to:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">{`Upload and pre-annotate their own images`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Customize lecture slides`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Connect, lead and monitor a group of OtoSim 2™ users to enable distance learning`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Create objective structured clinical examinations (OSCE) `}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  