import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { Box, Text } from 'grommet';
import Head from 'components/head';
import SectionTitle from 'components/title/sectionTitle';
import ProductIntro from 'components/productIntro';
import InformationBox from 'components/informationBox';
import { ImageStack, Card } from 'components/pageCards';
import { RequestBar, RequestBarExtended } from 'components/requestBar';
import Intro from '../../content/products/otosim2/intro.mdx';
import ProblemIntro from '../../content/products/otosim2/problemIntro.mdx';
import KeyFeatures from '../../content/products/otosim2/keyFeatures.mdx';
import SolutionIntro from '../../content/products/otosim2/solutionIntro.mdx';
import HealthBranches from '../../content/products/otosim2/healthBranches.mdx';
import FeaturedContent from '../../content/products/otosim2/featuredContent.mdx';
import Accessories from '../../content/products/otosim2/accessories.mdx';


import Video from 'components/video';



const Otosim2 = ({ data }) => (
  <Layout>
    <Head pageTitle={data.otosim2Json.title} />
    
   <Box fill="horizontal">
     <SectionTitle title="OtoSim 2™" />
      <Box width="xlarge" alignSelf="center">
        <Box margin={{ top: 'small', bottom: 'small' }}>
          <Video id="llYBC-GKBlY" />
          </Box>
          </Box>

        
       <ProductIntro>
        <Intro />
      </ProductIntro>
      <RequestBar />
      <ImageStack
        image={data.otosim2Json.sectionPic1}
        textColor="white"
        textAlign="right"
      >
        <ProblemIntro />
      </ImageStack>
      <Card image={data.otosim2Json.sectionPic2} alignImage="right">
        <SolutionIntro />
      </Card>
      <Card image={data.otosim2Json.sectionPic5} alignImage="left">
        <KeyFeatures />
      </Card>
      <ImageStack
        image={data.otosim2Json.sectionPic3}
        textColor="dark-1"
        textAlign="left"
      >
        <Text size="medium">
          <HealthBranches />
        </Text>
      </ImageStack>
      <ImageStack
        image={data.otosim2Json.sectionPic4}
        textColor="dark-1"
        textAlign="left"
      >
        <Text size="medium">
          <FeaturedContent />
        </Text>
      </ImageStack> 
    
      <InformationBox>
        <Accessories />
      </InformationBox>
     
     
        <RequestBarExtended />
    </Box>
  </Layout>
);

Otosim2.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Otosim2;

export const query = graphql`
  query Otosim2Query {
    otosim2Json {
      title
      sectionPic1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionPic2 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionPic3 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionPic4 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionPic5 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
