import React from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';

const informationBox = ({ children }) => (
  <Box
    alignSelf="center"
    margin="small"
    round="small"
    pad="small"
    width="xlarge"
    background="light-1"
  >
    <Text size="medium">{children}</Text>
  </Box>
);

informationBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default informationBox;