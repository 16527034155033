import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';
import { Link } from 'gatsby';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    OtoSim 2™ Key Features:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Verify student progress via the instrumented otoscope. Trainees can point with this device to complete the learning feedback loop.  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Expand student knowledge through reviewing over 400 high-resolution images from the Hawke Library to instruct, practice, and test students  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Save instructor time by connecting up to 14 OtoSim™ units to a single trainer laptop to effectively instruct groups at the same time  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Supplement classroom instruction with 150 pre-annotated images for self-directed learning  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Improve viewer retention through immersive full-screen experience with a simple, easy-to-use graphical user interface  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Increase student capability through advanced quizzes with randomized, realistic clinical scenarios that test both medical and patient interaction skills  `}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Build student confidence through the use of the instrumented otoscope, allowing students to practice and improve their otoscopic technique`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Add `}<Link to="/pneumatosim">{` PneumatoSim™`}</Link>{` (sold separately) to practice pneumatic otoscopy`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Add the `}<Link to="/educators-toolkit">{` Educators Tool Kit™`}</Link>{` (sold separately) to allow for complete customization of the otologic curriculum including adding your own images`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  