import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center" color="dark-2">
Following a three-hour group session using OtoSim™ Otoscopy Training & Simulation, medical students increased their accuracy in the diagnosis of various ear pathologies from 54% to 78%.
</Heading></MDXTag>

export const _frontmatter = {};

  